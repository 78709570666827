import { useRef, useEffect, useState } from 'react';
import {
  Text3D,
  useAnimations,
  useGLTF,
  PresentationControls,
} from '@react-three/drei';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { RigidBody, Physics } from '@react-three/rapier';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { Vector3, SphereGeometry, LoopOnce } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

/**
 * Enable shadows for the given object and its children.
 * @param {Object3D} object - The object to enable shadows for.
 */
const enableShadows = (object) => {
    if (object.isMesh) {
      object.castShadow = true;
      object.receiveShadow = true;
    }

    if (object.children) {
      object.children.forEach(enableShadows);
    }
};

/**
 * Generate a random float within a specified range.
 * @returns {number} The random float.
 */
const getRandomFloat = () => {
  const min = -0.1;
  const max = 0.1;
  return Math.random() * (max - min) + min;
};

/**
 * Create a mesh object with common properties.
 * @param {Object3D} scene - The scene object.
 * @param {number} scale - The scale of the object.
 * @param {Array} position - The position of the object.
 * @param {Function} onClick - The click event handler.
 * @param {Function} onPointerOver - The hover event handler.
 * @param {Function} onPointerOut - The hover end event handler.
 * @param {React.Ref} ref - The ref to be assigned to the mesh.
 * @returns {JSX.Element} The mesh JSX element.
 */
const createMeshObject = (scene, scale, position, onClick, onPointerOver, onPointerOut, ref) => (
    <mesh ref={ref} onClick={onClick} onPointerOver={onPointerOver} onPointerOut={onPointerOut} position={position}>
        <primitive object={scene} scale={scale} />;
    </mesh>
);


const createRBMeshObject = (scene, scale, position, onClick, onPointerOver, onPointerOut, ref) => (
    <RigidBody ref={ref} restitution={0.4}>
        <mesh onClick={onClick} onPointerOver={onPointerOver} onPointerOut={onPointerOut} position={position}>
            <primitive object={scene.clone()} scale={scale} />;
        </mesh>
    </RigidBody>
);

/**
 * Create a Text3D component with common properties.
 * @param {string} text - The text content.
 * @param {Array} position - The position of the Text3D.
 * @param {Function} onClick - The click event handler.
 * @param {Function} onPointerOver - The hover event handler.
 * @param {Function} onPointerOut - The hover end event handler.
 * @param {React.Ref} ref - The ref to be assigned to the Text3D.
 * @param {React.Ref} materialRef - The ref to be assigned to the meshPhongMaterial.
 * @returns {JSX.Element} The Text3D JSX element.
 */
const createText3D = (text, position, onClick, onPointerOver, onPointerOut, ref, materialRef) => (
    <RigidBody ref={ref} restitution={0.4} key={text}>
        <Text3D
            castShadow
            receiveShadow
            font="./fonts/helvetiker_regular.typeface.json"
            position={position}
            size={0.75}
            height={0.4}
            curveSegments={12}
            bevelEnabled
            bevelThickness={0.02}
            bevelSize={0.02}
            bevelOffset={0}
            bevelSegments={5}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onClick}
        >
            {text}
            <meshPhongMaterial ref={materialRef} attach="material" color="#E7C67C" emissive={'#CF835A'} emissiveIntensity={0} />
        </Text3D>
    </RigidBody>
);

/**
 * Main component representing the 3D experience.
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} The rendered JSX element.
 */
export default function Experience({ onObjectClick, loadingManager })
{
    const eyeRRef = useRef();
    const eyeLRef = useRef();
    const controllerRef = useRef();
    const videoCameraRef = useRef();
    const flagRef = useRef();
    const thimbleRef = useRef();
    const certificateRef = useRef();
    const originalMaterial = useRef();
    const textURef = useRef();
    const textPRef = useRef();
    const textFRef = useRef();
    const textUMaterialRef = useRef();
    const textPMaterialRef = useRef();
    const textFMaterialRef = useRef();

    const { camera } = useThree();
    camera.position.set(0.1, 5.6, 14.3);
    camera.lookAt(0, 0, 0);

    /**
     * Handle object click events.
     * @param {Event} e - The click event.
     */
    const handleObjectClick = (e) => {
        onObjectClick && onObjectClick(e);
    };

    /**
     * Change emissive intensity for the given object and its children.
     * @param {Object3D} object - The object to change emissive intensity for.
     * @param {number} intensity - The new emissive intensity.
     */
    const changeEmissiveIntensity = (object, intensity) => {
        if (object.isMesh) {
            if (!originalMaterial.current) {
                originalMaterial.current = object.material.clone();
            }
            if (object.material) {
                object.material.emissive.set('#CF835A');
                object.material.emissiveIntensity = intensity;
            }
        }

        if (object.children) {
            object.children.forEach((child) => {
                changeEmissiveIntensity(child, intensity);
            });
        }
    };

    /**
     * Handles hover over a scene by adjusting emissive intensity.
     * @param {Object3D} scene - The scene object to be hovered over.
     */
    const handleHover = (scene) => {
      changeEmissiveIntensity(scene, 0.2); // Adjust the color and intensity as needed
    };

    /**
     * Handles the end of hover over a scene by restoring the original material.
     * @param {Object3D} scene - The scene object where hover ends.
     */
    const handleHoverEnd = (scene) => {
      // Restore the original material
      changeEmissiveIntensity(scene, 0); // Change back to the original color and reset intensity
    };

    /**
     * Make the text elements jump.
     */
    const textJump = () => {
        const textUPFRefs = [textURef, textPRef, textFRef];
        textUPFRefs.forEach((ref) => {
            ref.current.wakeUp();
            ref.current.applyImpulse({ x: 0, y: 0.1, z: -0.1 });
            ref.current.applyTorqueImpulse({
                x: getRandomFloat(),
                y: getRandomFloat(),
                z: getRandomFloat(),
            });
        });
    };

    /**
     * Make the object elements jump.
     */
    const pushObject = (ref) => {
        const torque_mult = 20
        ref.current.wakeUp();
        ref.current.applyImpulse({ x: 0, y: 15, z: 0 });
        ref.current.applyTorqueImpulse({
            x: getRandomFloat() * torque_mult,
            y: getRandomFloat() * torque_mult,
            z: getRandomFloat() * torque_mult,
        });
    };

    let levitating = false;
    const levitateObject = (ref, position) => {
        if (!levitating) {
            levitating = true;
        } else {
            levitating = false;
            ref.current.position.x = position[0];
            ref.current.position.y = position[1];
            ref.current.position.z = position[2];
            ref.current.rotation.x = 0;
            ref.current.rotation.y = 0;
            ref.current.rotation.z = 0;
            levitationHeight = 0.07;
        }
    }

    /**
    * Handle text hover events.
    * @function
    * @param {boolean} hover - Indicates whether the text is being hovered.
    */
    const textHover = (hover) => {
        textUMaterialRef.current.emissiveIntensity = hover ? 1 : 0;
        textPMaterialRef.current.emissiveIntensity = hover ? 1 : 0;
        textFMaterialRef.current.emissiveIntensity = hover ? 1 : 0;
    };

    useFrame(({ camera, mouse, viewport }) => {
        const x = mouse.x * viewport.width * 10
        const y = mouse.y * viewport.height * 10

        // Calculate the world position of the mouse in front of the camera
        const mouseWorldPositionR = new Vector3(x, y, -1).unproject(camera)
        const mouseWorldPositionL = mouseWorldPositionR.clone()

        // Adjust the mouseWorldPosition based on the object's position
        mouseWorldPositionR.sub(eyeRRef.current.position);
        mouseWorldPositionL.sub(eyeLRef.current.position)

        // Make the box look at the calculated world position of the mouse
        eyeRRef.current.lookAt(mouseWorldPositionR)
        eyeLRef.current.lookAt(mouseWorldPositionL)
    })


    const [playFlagAnimation, setPlayFlagAnimation] = useState(false);

    const playFlag = () => {
        setPlayFlagAnimation(true)
        setTimeout(setPlayFlagAnimation, 2000, false)
    };

    useFrame(() =>
    {
        if (playFlagAnimation){
            const action = animations.actions.KeyAction
            action.setLoop(LoopOnce, 1)
            action.play()
            action.getMixer().addEventListener('finished', () => {
                action.stop();
            });
        }
    })

    const eyeball_scene = useLoader(GLTFLoader, './eyeball.glb', (loader) => {
      loader.manager = loadingManager;
    }).scene.clone();
    enableShadows(eyeball_scene);

    const controller_scene = useLoader(GLTFLoader, './controller.glb', (loader) => {
      loader.manager = loadingManager;
    }).scene.clone();
    enableShadows(controller_scene);

    const { scene: video_camera_scene } = useLoader(GLTFLoader, './video_camera.glb', (loader) => {
      loader.manager = loadingManager;
    });
    enableShadows(video_camera_scene);

    const { scene: video_camera_off_scene } = useLoader(GLTFLoader, './video_camera_off.glb', (loader) => {
      loader.manager = loadingManager;
    });
    enableShadows(video_camera_off_scene);

    const flag = useGLTF('./flag_wind.glb')
    const animations = useAnimations(flag.animations, flag.scene)
    enableShadows(flag.scene);

    const { scene: thimble_scene } = useGLTF('./thimble.glb');
    enableShadows(thimble_scene);

    const { scene: certificate_scene } = useLoader(GLTFLoader, './certificate.glb', (loader) => {
      loader.manager = loadingManager;
    });
    enableShadows(certificate_scene);

    const { scene: hexagon_scene } = useLoader(GLTFLoader, './hexagon.glb', (loader) => {
      loader.manager = loadingManager;
    });
    enableShadows(hexagon_scene);

    const hexagon_y = -0.97
    const hexagonPositions = [
      [0, hexagon_y, 0],
      [4.3, hexagon_y, 0],
      [-4.3, hexagon_y, 0],
      [4.3/2, hexagon_y, 3.7],
      [-4.3/2, hexagon_y, 3.7],
      [4.3/2, hexagon_y, -3.7],
      [-4.3/2, hexagon_y, -3.7]
    ];

    // Initialize an array to store buffer geometries
    const bufferGeometries = [];
    const hexagon_geometry = hexagon_scene.children[0].geometry

    hexagonPositions.forEach((position) => {
      // Clone the original hexagon geometry
      const clonedGeometry = hexagon_geometry.clone();
      const scale_factor = 2.48
      clonedGeometry.scale(scale_factor, scale_factor, scale_factor)
      // Apply translation to the cloned geometry
      clonedGeometry.translate(position[0], position[1], position[2]);
      // Add the cloned geometry to the array
      bufferGeometries.push(clonedGeometry);
    });
    // Merge buffer geometries into a single geometry
    const mergedBufferGeometry = BufferGeometryUtils.mergeGeometries(bufferGeometries);

    // Particles
    const numElements = 16; // You can change this number to whatever you need
    // Create initial positions
    const initialPositions = Array.from({ length: numElements }, () => [-4.3/2, 0.5, 3.7]);
    // Create unique angles for each sphere
    const angles = useRef(
        Array.from({ length: numElements }, (_, i) => (i * 2 * Math.PI) / numElements + (Math.random() - 0.5) * 0.1)
    );
    const initialSphereSize = 0.07
    const [spherePositions, setPositions] = useState(initialPositions);
    const [sphereSize, setSphereSize] = useState(0);
    const directionMultiplier = useRef(
        Array.from({ length: numElements }, (_, i) => (Math.random() - 0.5) * 0.1)
    );
    const initialSphereDirection = 1.05
    const [sphereDirection, setSphereDirection] = useState(initialSphereDirection);

    useFrame((state, delta) => {
        if (sphereSize > 0.02) {
            setPositions((prevPositions) => {
              return prevPositions.map((position, index) => {
                const angle = angles.current[index];
                const speed = 0.1;
                const newX = position[0] + speed * Math.cos(angle);
                const newZ = position[2] + speed * Math.sin(angle);
                // Simple up and down motion for Y
                const newY = position[1] * sphereDirection + directionMultiplier.current[index]
                return [newX, newY, newZ];
              });
            });
            setSphereSize(sphereSize * 0.95)
            const newGeometry = createMergedGeometrySpheres(spherePositions, sphereSize);
            setMergedGeometrySpheres(newGeometry);
            setSphereDirection(sphereDirection - 0.004)

        }else if(sphereSize > 0){
            setSphereSize(0)
            const newGeometry = createMergedGeometrySpheres(initialPositions, sphereSize);
            setMergedGeometrySpheres(newGeometry);
        }
    });

    const createMergedGeometrySpheres = (positions, radius) => {

        const bufferGeometriesSpheres = [];

        positions.forEach(position => {
          const sphereGeometry = new SphereGeometry(radius, 32, 32);
          sphereGeometry.translate(position[0], position[1], position[2]);
          bufferGeometriesSpheres.push(sphereGeometry);
        });

        return BufferGeometryUtils.mergeGeometries(bufferGeometriesSpheres);
    }

    const [mergedBufferGeometrySpheres, setMergedGeometrySpheres] = useState();

    const particleEffect = () => {
        setSphereDirection(initialSphereDirection)
        setPositions(initialPositions)
        setSphereSize(initialSphereSize)
        const newGeometry = createMergedGeometrySpheres(spherePositions, sphereSize);
        setMergedGeometrySpheres(newGeometry);
    };

    const [cameraSceneSwitch, setCameraSceneSwitch] = useState(false);
    const camera_switch = () => {
        setCameraSceneSwitch(!cameraSceneSwitch)
    };

    const [certificateRotation, setCertificateRotation] = useState(false);
    const rotateCertifacete = () => {
        setCertificateRotation(true)
        setTimeout(setCertificateRotation, 1000, false)
    };

    useFrame((state, delta) => {
        if (certificateRotation){
            certificate_scene["children"][0].rotation.z += delta * 5;
        }
    })

    return <>
        <directionalLight
            castShadow
            position={ [ -2, 5, 3.5 ] }
            intensity={ 0.6 }
            shadow-normalBias={ 0.04 }
            shadow-mapSize={ [  1024, 1024 ] }
        />
        <ambientLight intensity={ 0.2 } />

        <PresentationControls
            global
            polar={ [ -0.2, 0.4 ] }
            azimuth={ [ - 1, 1 ] }
            config={ { mass: 2, tension: 300 } }
            snap={ { mass: 3, tension: 300 } }
        >
            <Physics>
                {createMeshObject(
                  eyeball_scene.clone(),
                  0.5,
                  [1.65, 0.5, 3.7],
                  () => handleObjectClick(2),
                  () => handleHover(eyeball_scene),
                  () => handleHoverEnd(eyeball_scene),
                  eyeLRef
                )}
                {createMeshObject(
                  eyeball_scene.clone(),
                  0.5,
                  [2.65, 0.5, 3.7],
                  () => handleObjectClick(2),
                  () => handleHover(eyeball_scene),
                  () => handleHoverEnd(eyeball_scene),
                  eyeRRef
                )}
                {createMeshObject(
                  controller_scene,
                  0.5,
                  [-4.3/2, 0.1, 3.7],
                  () => { handleObjectClick(1); particleEffect()},
                  () => handleHover(controller_scene),
                  () => handleHoverEnd(controller_scene),
                  controllerRef,
                )}
                {createMeshObject(
                  cameraSceneSwitch ? video_camera_scene : video_camera_off_scene,
                  1,
                  [0, 0, 0],
                  () => { handleObjectClick(3); camera_switch() },
                  () => {handleHover(video_camera_scene); handleHover(video_camera_off_scene)},
                  () => {handleHoverEnd(video_camera_scene); handleHoverEnd(video_camera_off_scene)}
                )}
                {createMeshObject(
                  flag.scene,
                  0.7,
                  [-4.3/2, 0, -3.7],
                  () => {handleObjectClick(4); playFlag()},
                  () => handleHover(flag.scene),
                  () => handleHoverEnd(flag.scene)
                )}
                {createRBMeshObject(
                  thimble_scene,
                  0.8,
                  [-4.3, 0.8, 0],
                  () => { handleObjectClick(6); pushObject(thimbleRef)},
                  () => handleHover(thimble_scene),
                  () => handleHoverEnd(thimble_scene),
                  thimbleRef
                )}
                {createMeshObject(
                  certificate_scene,
                  0.5,
                  [4.3/2, 0.05, -3.5 ],//[4.1, 0.03, -0.2],
                  () => { handleObjectClick(7); rotateCertifacete()},
                  () => handleHover(certificate_scene),
                  () => handleHoverEnd(certificate_scene)
                )}
                {createText3D(
                  'R',
                  [3.4, 0.02, -0.2],
                  () => { handleObjectClick(5); textJump(); },
                  () => textHover(true),
                  () => textHover(false),
                  textURef,
                  textUMaterialRef
                )}
                {createText3D(
                  '&',
                  [4.1, 0.05, -0.2],
                  () => { handleObjectClick(5); textJump(); },
                  () => textHover(true),
                  () => textHover(false),
                  textPRef,
                  textPMaterialRef
                )}
                {createText3D(
                  'D',
                  [4.85, 0.02, -0.2],
                  () => { handleObjectClick(5); textJump(); },
                  () => textHover(true),
                  () => textHover(false),
                  textFRef,
                  textFMaterialRef
                )}

                <RigidBody type="fixed">
                    <mesh
                      geometry={mergedBufferGeometry}
                      material={hexagon_scene.children[0].material}
                      receiveShadow={true}
                    />
                </RigidBody>

                <mesh 
                    geometry={mergedBufferGeometrySpheres}>
                    <meshPhongMaterial attach="material" color="#E7C67C" emissive={'#E7C67C'} />
                </mesh>

            </Physics>

        </PresentationControls>

    </>
}