import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'

export default function CustomNavbar ()
{
    const navbarStyle = {
      height: '80px',
      backgroundColor: 'transparent',
      zIndex: 2,
      /*margin: '10px 20px'*/
    };
    const navItemStyle = {
      fontFamily: 'Nunito',
      color: 'white',
      margin: '0 10px'
    };
    const homeStyle = {
      fontFamily: 'Nunito',
      color: 'white',
      margin: '0 30px'
    };
    return <>
      <Navbar variant="dark" expand="lg" style={navbarStyle}>
        <Navbar.Brand style={homeStyle}>Aina Nicolau Orell</Navbar.Brand>
{/*        <Navbar.Brand href="#home" style={homeStyle}>Aina Nicolau Orell</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
{/*        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" style={{ marginLeft: 'auto', marginRight: '10px' }}>
            <Nav.Link href="#home" style={navItemStyle}>HOME</Nav.Link>
            <Nav.Link href="#about" style={navItemStyle}>ABOUT</Nav.Link>
            <Nav.Link href="#projects" style={navItemStyle}>PROJECTS</Nav.Link>
            <Nav.Link href="#contact" style={navItemStyle}>CONTACT</Nav.Link>
          </Nav>
        </Navbar.Collapse>*/}
      </Navbar>
    </>
}


