import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// First day at work
const startDate = new Date('2016-12-09');

const calculateExperienceYears = (startDate) => {
    const currentDate = new Date();
    const experienceYears = currentDate.getFullYear() - startDate.getFullYear();
    const monthDifference = currentDate.getMonth() - startDate.getMonth();

    // Ajustar si el mes actual es anterior al mes de inicio
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < startDate.getDate())) {
        return experienceYears - 1;
    }

    return experienceYears;
};

function CustomText(text) {
  const containerStyle = {
            width: "25%",
            float: "left",
            padding: "10px"
  };

  const titleStyle = {
    fontSize: '27px',
    fontFamily: 'Nunito',
    color: '#E3E9E2',
    margin: '10 0px',
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  };

  const paragraphStyle = {
    fontFamily: 'Nunito',
    color: 'white',
    margin: '10px 0px',
    backgroundColor: 'transparent',
  };

  const experienceYears = calculateExperienceYears(startDate);

  return (
    <Container style={containerStyle}>

          <h2 style={titleStyle}>{text.text.title}</h2>
          <p style={paragraphStyle}>
            {text.text.content ? text.text.content.replace("experience-years", experienceYears) : ''}
          </p>

    </Container>
  );
}

export default CustomText;
