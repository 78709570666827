export const textOptions = [
  { id: 0, title: '', content: '' },
  { id: 1, title: 'INTERNSHIP', content: 'I completed an internship at Tragnarion Studios, where I worked as a Technical Artist and created various VFX with UE4\'s Cascade particle system.' },
  { id: 2, title: 'UNDEGRADUATE THESIS', content: '"Gaze Detection Using Random Forests" utilizes a dataset of eye images from various subjects, processing them with the Histogram of Oriented Gradients (HOG) method to achieve notably better precision, and subsequently employs the processed data to create a regression model with Random Forest.' },
  { id: 3, title: 'DEGREE SUBJECTS', content: 'During my Bachelor\'s Degree in Audiovisual System Engineering, I learned about different areas like programming, image and video processing, pattern recognition, and image analysis.'},
  { id: 4, title: 'MASTER THESIS', content: 'This project, "Position Based Dynamics for Character Effects" presents an implementation of cloth, hair and soft bodies simulation using Position Based Dynamics (PBD). The solver has been implemented in Houdini using VEX language.'},
  { id: 5, title: 'RESEARCH PROJECT', content: 'For "Procedural Modeling of Hydraulic Tile Patterns using Shape Grammars" I implemented a tool in Houdini to interactively generate procedural patterns with the same style as hydraulic tiles. I achieved this by using Shape Grammar rules, analyzing examples of tiles, and identifying common patterns to synthesize them.'},
  { id: 6, title: '3D PROJECTS', content: 'During the MSc Computer Animation and Visual Effects program I worked on projects like a Thimble Rendering, PBD Sand Simulation, Penrose Stairs Modeling, Procedural City Generator HDA, Climbing Plant Generation using L-Systems, and a short film group project.'},
  { id: 7, title: 'CERTIFICATES', content: 'Agile, thePower (Sep 2022) <br>\
                                            ThePowerMBA, thePower (Apr 2022) <br>\
                                            ScrumMaster, Scrum Alliance (Apr 2018)'},
  { id: 8, title: 'FILMOGRAPHY', content:   'Ron\'s Gone Wrong (2021) - Character Effects <br>\
                                            The Lion King (2019) - Technical Animation & Software Development <br>\
                                            Alien: Covenant (2017) - Technical Animation'},
  { id: 9, title: 'CHARACTER EFFECTS ARTIST', content: 'Employed at both MPC and DNEG, I specialized in creating procedural setups for cloth and hair dynamics, alongside generating fur simulations using Houdini. Additionally, I conducted skin corrections and managed environment dynamics with Maya.'},
  { id: 10, title: 'TOOL DEVELOPMENT', content: 'During my time in the character effects departments at MPC and DNEG, I was responsible for creating and maintaining artistic tools, collaborating closely with the software team. I enhanced workflows using Maya, Houdini and Nuke scripts, and developed tools for automation. Additionally, I provided Python support to senior artists.'},
  { id: 11, title: '3D SOFTWARE ENGINEER', content: 'While I was at MPC\'s software team, I focused on implementing and supporting pipeline features to facilitate smooth asset delivery across departments. This involved configuring tools for various shows, such as a cross-department rendering tool using Python, Maya, Katana, and Nuke. I collaborated in a Linux environment following Scrum methodologies, and successfully integrated a fur simulation tool into multiple 3D packages using C++.'},
  { id: 12, title: 'AI SOFTWARE ENGINEER', content: 'As a Software Engineer at Humanising Autonomy, I developed tools for the AI Research Team and created demos and web applications showcasing behavioral AI model results. I optimized workflows with Python and PyQt, designed a video analytics dashboard using Django and JavaScript, and implemented a RESTful API for efficient video processing. Additionally, I created a real-time emotion analysis frontend application with MediaPipe.'},
  { id: 13, title: '3D PACKAGES', content: 'I have extensive experience working with various 3D software such as Maya, Houdini, Blender, Katana, and Nuke. My focus lies in crafting dynamic templates for hair and cloth simulations. Additionally, I\'ve played a key role in developing custom tools and Python scripts, particularly in Houdini, to enhance and automate workflows for Modeling, Animation, and Technical Animation teams.'},
  { id: 14, title: 'PROGRAMMING SKILLS', content: 'Skilled in Python, JavaScript, VEX, and C++, I specialize in optimizing workflows and pipeline development. With Python, I streamline processes within 3D software, crafting efficient tools for artists and researchers. My extensive experience ensures high-quality code, with proper structure and documentation. In JavaScript, I created interactive web applications, merging creativity with technical skills to produce engaging designs.'}
];

export const stageDescription = [
  { id: 0, title: 'Education & Certificates', content: 'My academic journey started in Barcelona, where I studied for a Bachelor\'s Degree in Audiovisual System Engineering at Pompeu Fabra University. At the same time, I gained practical experience through an internship at a video game company. Continuing my academic journey, I graduated with Distinction from Bournemouth University with an MSc in Computer Animation and Visual Effects.'},
  { id: 1, title: 'Work Experience & Skills', content: 'Continuing my professional journey, I have accumulated over experience-years years of experience, primarily as a Software Engineer. However, I have also explored various roles, including artistic positions, within the 3D Animation, VFX, and AI industries. During my time in London, which spanned 6 years, I worked for various studios as well as a startup. Recently, I made the decision to relocate back to Mallorca, where I am currently working remotely.' }
]