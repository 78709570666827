import React, { useState, useEffect } from "react";

function MobilePopup() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  if (!isMobile) {
    return null;
  }

  return (
    <div className="mobile-popup">
      <div className="popup-content">
        <p>This app was implemented for a larger screen.</p>
        <p>Please open it on a PC or laptop for the best experience.</p>
        <button onClick={() => setIsMobile(false)}>Close</button>
      </div>
    </div>
  );
}

export default MobilePopup;
