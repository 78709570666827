import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  const footerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '60px', /* Adjust the height of the footer as needed */
    background: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
    zIndex: 1,
  };

  const iconStyle = {
    color: 'white',
    margin: '0 10px'
  };

  return (
    <footer style={footerStyle}>
      <a href="https://github.com/ainanicolau" target="_blank" rel="noopener noreferrer" style={iconStyle}>
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </a>
      <a href="https://www.linkedin.com/in/aina-nicolau-orell-a6800aa4/" target="_blank" rel="noopener noreferrer" style={iconStyle}>
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
    </footer>
  );
}

export default Footer;
