import { useRef, useState, useEffect } from 'react';
import {
  Html,
  Text3D,
  useAnimations,
  useGLTF,
  PresentationControls,
} from '@react-three/drei';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { RigidBody, Physics } from '@react-three/rapier';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { Vector3, SphereGeometry, MeshStandardMaterial, LoopOnce } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';

/**
 * Enable shadows for the given object and its children.
 * @param {Object3D} object - The object to enable shadows for.
 */
const enableShadows = (object) => {
    if (object.isMesh) {
      object.castShadow = true;
      object.receiveShadow = true;
    }

    if (object.children) {
      object.children.forEach(enableShadows);
    }
};

/**
 * Generate a random float within a specified range.
 * @returns {number} The random float.
 */
const getRandomFloat = () => {
  const min = -0.1;
  const max = 0.1;
  return Math.random() * (max - min) + min;
};

/**
 * Create a mesh object with common properties.
 * @param {Object3D} scene - The scene object.
 * @param {number} scale - The scale of the object.
 * @param {Array} position - The position of the object.
 * @param {Function} onClick - The click event handler.
 * @param {Function} onPointerOver - The hover event handler.
 * @param {Function} onPointerOut - The hover end event handler.
 * @param {React.Ref} ref - The ref to be assigned to the mesh.
 * @returns {JSX.Element} The mesh JSX element.
 */
const createMeshObject = (scene, scale, position, rotation, onClick, onPointerOver, onPointerOut, ref) => (
    <mesh ref={ref} onClick={onClick} onPointerOver={onPointerOver} onPointerOut={onPointerOut} position={position} rotation={rotation}>
        <primitive object={scene} scale={scale} />;
    </mesh>
);

const createPCObject = (scene, scale, position, rotation, onClick, onPointerOver, onPointerOut, showIframeVar) => (
    <mesh onClick={onClick} onPointerOver={onPointerOver} onPointerOut={onPointerOut} position={position} rotation={rotation}>
        <primitive object={scene} scale={scale} />;
        {showIframeVar && (
            <Html
                transform
                wrapperClass="htmlScreen"
                distanceFactor={ 2 }
                position={ [ 0, 1.56, - 0.65 ] }
            >
                <iframe src="https://player.vimeo.com/video/178102012?h=d558b74301" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </Html>
        )}
    </mesh>
);

/**
 * Create a Text3D component with common properties.
 * @param {string} text - The text content.
 * @param {Array} position - The position of the Text3D.
 * @param {Function} onClick - The click event handler.
 * @param {Function} onPointerOver - The hover event handler.
 * @param {Function} onPointerOut - The hover end event handler.
 * @param {React.Ref} ref - The ref to be assigned to the Text3D.
 * @param {React.Ref} materialRef - The ref to be assigned to the meshPhongMaterial.
 * @returns {JSX.Element} The Text3D JSX element.
 */
const createText3D = (text, position, onClick, onPointerOver, onPointerOut, ref, materialRef) => (
    <RigidBody ref={ref} restitution={0.4} key={text}>
        <Text3D
            castShadow
            receiveShadow
            font="./fonts/helvetiker_regular.typeface.json"
            position={position}
            size={0.85}
            height={0.4}
            curveSegments={12}
            bevelEnabled
            bevelThickness={0.02}
            bevelSize={0.02}
            bevelOffset={0}
            bevelSegments={5}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
            onClick={onClick}
        >
            {text}
            <meshPhongMaterial ref={materialRef} attach="material" color="#E7C67C" emissive={'#CF835A'} emissiveIntensity={0} />
        </Text3D>
    </RigidBody>
);

/**
 * Main component representing the 3D experience.
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} The rendered JSX element.
 */
export default function WorkExperience({ onObjectClick })
{
/*    const eyeRRef = useRef();
    const eyeLRef = useRef();
    const textFRef = useRef();
    const textFMaterialRef = useRef();
    const controllerRef = useRef();
    const videoCameraRef = useRef();
    const balloonRef = useRef();*/
    const originalMaterial = useRef();
    const textURef = useRef();
    const textPRef = useRef();
    const textUMaterialRef = useRef();
    const textPMaterialRef = useRef();
    // const pcRef = useRef()
    // const clapperRef = useRef()
    // const combRef = useRef()
    // const magicWandRef = useRef()
    // const rubikCubeRef = useRef()

    const { camera } = useThree();
    camera.position.set(0.1, 5.6, 14.3);
    camera.lookAt(0, 0, 0);

    /**
     * Handle object click events.
     * @param {Event} e - The click event.
     */
    const handleObjectClick = (e) => {
        onObjectClick && onObjectClick(e);
    };

    /**
     * Change emissive intensity for the given object and its children.
     * @param {Object3D} object - The object to change emissive intensity for.
     * @param {number} intensity - The new emissive intensity.
     */
    const changeEmissiveIntensity = (object, intensity) => {
        if (object.isMesh) {
            if (!originalMaterial.current) {
                originalMaterial.current = object.material.clone();
            }
            if (object.material) {
                object.material.emissive.set('#CF835A');
                object.material.emissiveIntensity = intensity;
            }
        }

        if (object.children) {
            object.children.forEach((child) => {
                changeEmissiveIntensity(child, intensity);
            });
        }
    };

    /**
     * Handles hover over a scene by adjusting emissive intensity.
     * @param {Object3D} scene - The scene object to be hovered over.
     */
    const handleHover = (scene) => {
      changeEmissiveIntensity(scene, 0.2); // Adjust the color and intensity as needed
    };

    /**
     * Handles the end of hover over a scene by restoring the original material.
     * @param {Object3D} scene - The scene object where hover ends.
     */
    const handleHoverEnd = (scene) => {
      // Restore the original material
      changeEmissiveIntensity(scene, 0); // Change back to the original color and reset intensity
    };


    /**
    * Handle text hover events.
    * @function
    * @param {boolean} hover - Indicates whether the text is being hovered.
    */
    const textHover = (hover) => {
        textUMaterialRef.current.emissiveIntensity = hover ? 1 : 0;
        textPMaterialRef.current.emissiveIntensity = hover ? 1 : 0;
        // textFMaterialRef.current.emissiveIntensity = hover ? 1 : 0;
    };

    const gearHover = (hover) => {
        const intensity = hover ? 0.2 : 0;
        changeEmissiveIntensity(gear_scene_1, intensity);
        changeEmissiveIntensity(gear_scene_2, intensity);

    };


    /**
    * Handle text hover events.
    * @function
    * @param {boolean} hover - Indicates whether the text is being hovered.
    */
    const cubeHover = (hover) => {
        for (let x = 0; x < 27; x++) {
            cube_mat_refs[x].current.emissiveIntensity = hover ? 0.2 : 0;
        }
    };


    /**
     * Make the text elements jump.
     */
    const textJump = () => {
        //const textUPFRefs = [textURef, textPRef];
        textURef.current.wakeUp();
        textURef.current.applyImpulse({ x: 0, y: 0.1, z: -0.1 });
        textURef.current.applyTorqueImpulse({
            x: getRandomFloat(),
            y: getRandomFloat(),
            z: getRandomFloat(),
        });

        textPRef.current.wakeUp();
        textPRef.current.applyImpulse({ x: 0, y: 0.01, z: -0.01 });
        textPRef.current.applyTorqueImpulse({
            x: getRandomFloat() * 0.1,
            y: getRandomFloat() * 0.1,
            z: getRandomFloat() * 0.1
        });
        
    };

    /**
     * Make the object elements jump.
     */
    const pushObject = (ref) => {
        const torque_mult = 0.5
        ref.current.wakeUp();
        ref.current.applyImpulse({ x:0 , y: 0, z: -0.3 });
        ref.current.applyTorqueImpulse({
            x: getRandomFloat() * torque_mult,
            y: getRandomFloat() * torque_mult,
            z: getRandomFloat() * torque_mult,
        });
    };



/*    useFrame(({ camera, mouse, viewport }) => {
        const x = mouse.x * viewport.width * 10
        const y = mouse.y * viewport.height * 10

        // Calculate the world position of the mouse in front of the camera
        const mouseWorldPositionR = new Vector3(x, y, -1).unproject(camera)
        const mouseWorldPositionL = mouseWorldPositionR.clone()

        // Adjust the mouseWorldPosition based on the object's position
        mouseWorldPositionR.sub(eyeRRef.current.position);
        mouseWorldPositionL.sub(eyeLRef.current.position)

        // Make the box look at the calculated world position of the mouse
        eyeRRef.current.lookAt(mouseWorldPositionR)
        eyeLRef.current.lookAt(mouseWorldPositionL)
    })*/

    const { scene: clapper_scene } = useGLTF('./clapper.glb');
    enableShadows(clapper_scene);
    const plant = useGLTF('./plant.glb')
    const animations = useAnimations(plant.animations, plant.scene)
    enableShadows(plant.scene);
    const { scene: magic_wand_scene } = useGLTF('./magic_wand.glb');
    enableShadows(magic_wand_scene);
    const { scene: rubik_cube_scene } = useGLTF('./rubik_cube.glb');
    enableShadows(rubik_cube_scene);
    const { scene: gear_scene_1 } = useGLTF('./gear.glb');
    enableShadows(gear_scene_1);
    const { scene: gear_scene_2 } = useGLTF('./gear2.glb');
    enableShadows(gear_scene_2);
    const { scene: pc_scene } = useGLTF('./pc.glb');
    enableShadows(pc_scene);
    const { scene: hexagon_scene } = useGLTF('./hexagon.glb');
    enableShadows(hexagon_scene);

    const hexagon_y = -0.97
    const hexagonPositions = [
      [0, hexagon_y, 0],
      [4.3, hexagon_y, 0],
      [-4.3, hexagon_y, 0],
      [4.3/2, hexagon_y, 3.7],
      [-4.3/2, hexagon_y, 3.7],
      [4.3/2, hexagon_y, -3.7],
      [-4.3/2, hexagon_y, -3.7]
    ];

    // Initialize an array to store buffer geometries
    const bufferGeometries = [];
    const hexagon_geometry = hexagon_scene.children[0].geometry

    hexagonPositions.forEach((position) => {
      // Clone the original hexagon geometry
      const clonedGeometry = hexagon_geometry.clone();
      const scale_factor = 2.48
      clonedGeometry.scale(scale_factor, scale_factor, scale_factor)
      // Apply translation to the cloned geometry
      clonedGeometry.translate(position[0], position[1], position[2]);
      // Add the cloned geometry to the array
      bufferGeometries.push(clonedGeometry);
    });
    // Merge buffer geometries into a single geometry
    const mergedBufferGeometry = BufferGeometryUtils.mergeGeometries(bufferGeometries);


    // Particles
    const numElements = 16; // You can change this number to whatever you need
    // Create initial positions
    const initialPositions = Array.from({ length: numElements }, () => [4.7/2, 2.6, -3.5]);
    // Create unique angles for each sphere
    const angles = useRef(
        Array.from({ length: numElements }, (_, i) => (i * 2 * Math.PI) / numElements + (Math.random() - 0.5) * 0.1)
    );
    const initialSphereSize = 0.07
    const [spherePositions, setPositions] = useState(initialPositions);
    const [sphereSize, setSphereSize] = useState(0);
    const directionMultiplier = useRef(
        Array.from({ length: numElements }, (_, i) => (Math.random() - 0.5) * 0.1)
    );
    const initialSphereDirection = 1.05
    const [sphereDirection, setSphereDirection] = useState(initialSphereDirection);

    useFrame((state, delta) => {
        if (sphereSize > 0.02) {
            setPositions((prevPositions) => {
              return prevPositions.map((position, index) => {
                const angle = angles.current[index];
                const speed = 0.1;
                const newX = position[0] + speed * Math.cos(angle);
                const newZ = position[2] + speed * Math.sin(angle);
                // Simple up and down motion for Y
                const newY = position[1] * sphereDirection + directionMultiplier.current[index]
                return [newX, newY, newZ];
              });
            });
            setSphereSize(sphereSize * 0.95)
            const newGeometry = createMergedGeometrySpheres(spherePositions, sphereSize);
            setMergedGeometrySpheres(newGeometry);
            setSphereDirection(sphereDirection - 0.004)

        }else if(sphereSize > 0){
            setSphereSize(0)
            const newGeometry = createMergedGeometrySpheres(initialPositions, sphereSize);
            setMergedGeometrySpheres(newGeometry);
        }
    });

    const createMergedGeometrySpheres = (positions, radius) => {

        const bufferGeometriesSpheres = [];

        positions.forEach(position => {
          const sphereGeometry = new SphereGeometry(radius, 32, 32);
          sphereGeometry.translate(position[0], position[1], position[2]);
          bufferGeometriesSpheres.push(sphereGeometry);
        });

        return BufferGeometryUtils.mergeGeometries(bufferGeometriesSpheres);
    }

    const [mergedBufferGeometrySpheres, setMergedGeometrySpheres] = useState();

    const particleEffect = () => {
        setSphereDirection(initialSphereDirection)
        setPositions(initialPositions)
        setSphereSize(initialSphereSize)
        const newGeometry = createMergedGeometrySpheres(spherePositions, sphereSize);
        setMergedGeometrySpheres(newGeometry);
    };

    const rubik_positions = [];
    const size = 0.4; // Espaciado entre cubos
    const pos =  [-4.3/2, 0.6, 3.7];
    for (let x = -1; x <= 1; x++) {
        for (let y = -1; y <= 1; y++) {
            for (let z = -1; z <= 1; z++) {
                rubik_positions.push(new Vector3(x * size + pos[0], y * size  + pos[1], z * size  + pos[2]));
            }
        }
    }
    const cube_geometry = new RoundedBoxGeometry(0.4, 0.4, 0.4, 10, 0.06);
    // enableShadows(cube_geometry)
    const cube_refs = []
    const cube_mat_refs = []
    for (let x = 0; x < 27; x++) {
        const cubeRef = useRef()
        cube_refs.push(cubeRef)
        const cubeMaterialRef = useRef()
        cube_mat_refs.push(cubeMaterialRef)
    }


    const [gearRotation, setGearRotation] = useState(false);
    const rotateGear = () => {
        setGearRotation(true)
        setTimeout(setGearRotation, 1000, false)
    };

    useFrame((state, delta) => {
        if (gearRotation){
            gear_scene_1["children"][0].rotation.y += delta * 3;
            gear_scene_2["children"][0].rotation.y -= delta * 3;
        }
    })

    const [showIframe, setShowIframe] = useState(false);

    const pcSwitch = () => {
        setShowIframe(!showIframe)
    }

    const [clapperRotation, setClapperRotation] = useState(false);
    const [clapperOrientation, setClapperOrientation] = useState(1);
    const rotateClapper = () => {
        setClapperRotation(true)
        setTimeout(setClapperRotation, 1000, false)
        setTimeout(resetClapperRotation, 1000)
    };

    const resetClapperRotation = () => {
        clapper_scene["children"][2].rotation.z = 0
    }

    useFrame((state, delta) => {
        if (clapperRotation ){
            if (clapper_scene["children"][2].rotation.z>-0.25){
                clapper_scene["children"][2].rotation.z -= delta * 2;
            }
            else {
                clapper_scene["children"][2].rotation.z = -0.3
            }
        }
    })

    const [playPlantAnimation, setPlayPlantAnimation] = useState(false);

    const playPlant = () => {
        setPlayPlantAnimation(true)
        setTimeout(setPlayPlantAnimation, 1000, false)
    };

    useFrame(() =>
    {
        if (playPlantAnimation){
            const action0 = animations.actions["KeyAction.001"]
            action0.setLoop(LoopOnce, 0)
            action0.play()
            action0.getMixer().addEventListener('finished', () => {
                action0.stop();
            });
            const action1 = animations.actions["Key.001Action.001"]
            action1.setLoop(LoopOnce, 0)
            action1.play()
            action1.getMixer().addEventListener('finished', () => {
                action1.stop();
            });
            const action2 = animations.actions["Key.002Action.001"]
            action2.setLoop(LoopOnce, 0)
            action2.play()
            action2.getMixer().addEventListener('finished', () => {
                action2.stop();
            });
        }
    })

    return <>
        <directionalLight
            castShadow
            position={ [ -2, 5, 3.5 ] }
            intensity={ 0.6 }
            shadow-normalBias={ 0.04 }
            shadow-mapSize={ [  1024, 1024 ] }
        />
        <ambientLight intensity={ 0.2 } />

        <PresentationControls
            global
            polar={ [ -0.2, 0.4 ] }
            azimuth={ [ - 1, 1 ] }
            config={ { mass: 2, tension: 300 } }
            snap={ { mass: 3, tension: 300 } }
        >
            <Physics>

                {createMeshObject(
                  clapper_scene,
                  0.8,
                  [-4.3, 0.5, 0],
                  [0, 0, 0],
                  () => {handleObjectClick(8); rotateClapper()},
                  () => handleHover(clapper_scene),
                  () => handleHoverEnd(clapper_scene)
                )}
                {createMeshObject(
                  plant.scene,
                  1.1,
                  [-4.3/2, 1, -3.7],
                  [0, 2.5, 0],
                  () => {handleObjectClick(9); playPlant()},
                  () => handleHover(plant.scene),
                  () => handleHoverEnd(plant.scene)
                )}

                {rubik_positions.map((t_position, index) => (
                    <RigidBody ref={cube_refs[index]} key={index}>
                        <mesh
                          geometry={cube_geometry}
                          // material={hexagon_scene.children[0].material}
                          position={t_position}
                          onClick={() => {handleObjectClick(10); pushObject(cube_refs[index])}}
                          onPointerOver={() => cubeHover(true)}
                          onPointerOut={() => cubeHover(false)}
                          castShadow={true}
                        >
                            <meshPhongMaterial ref={cube_mat_refs[index]} attach="material" color="#92AC95" emissive={'#CF835A'} emissiveIntensity={0} />
                        </mesh>
                    </RigidBody>
                ))}

                {createMeshObject(
                  gear_scene_1,
                  0.7,
                  [4.3/2-0.6, 0, 4],
                  [0, 0, 0],
                  () => {handleObjectClick(11); rotateGear()},
                  () => gearHover(true),
                  () => gearHover(false)
                )}
                {createMeshObject(
                  gear_scene_2,
                  0.7,
                  [4.3/2+0.6, 0, 3],
                  [0, 0.2, 0],
                  () => {handleObjectClick(11); rotateGear()},
                  () => gearHover(true),
                  () => gearHover(false)
                )}
                {createMeshObject(
                  magic_wand_scene,
                  0.4,
                  [4.3/2, 1.5, -3.7],
                  [0.2, -0.2, -0.2],
                  () => {handleObjectClick(13); particleEffect()},
                  () => handleHover(magic_wand_scene),
                  () => handleHoverEnd(magic_wand_scene)
                )}
                {createPCObject(
                  pc_scene,
                  1,
                  [0, 0, 0],
                  [0, 0, 0],
                  () => {handleObjectClick(14); pcSwitch()},
                  () => handleHover(pc_scene),
                  () => handleHoverEnd(pc_scene),
                  showIframe
                )}

                <RigidBody type="fixed">
                    <mesh
                      geometry={mergedBufferGeometry}
                      material={hexagon_scene.children[0].material}
                      receiveShadow={true}
                    />
                </RigidBody>

                <mesh
                    geometry={mergedBufferGeometrySpheres}>
                    <meshPhongMaterial attach="material" color="#E7C67C" emissive={'#E7C67C'} />
                </mesh>

               {createText3D(
                  'A',
                  [3.8, 0.055, -0.2],
                  () => { handleObjectClick(12); textJump(); },
                  () => textHover(true),
                  () => textHover(false),
                  textURef,
                  textUMaterialRef
                )}
                {createText3D(
                  'I',
                  [4.7, 0.055, -0.2],
                  () => { handleObjectClick(12); textJump(); },
                  () => textHover(true),
                  () => textHover(false),
                  textPRef,
                  textPMaterialRef
                )}
{/*                 {createText3D(
                  'F',
                  [4.7, 0.03, -0.2],
                  () => { handleObjectClick(5); textJump(); },
                  () => textHover(true),
                  () => textHover(false),
                  textFRef,
                  textFMaterialRef
                )}*/}

            </Physics>

        </PresentationControls>

    </>
}