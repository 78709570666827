import React, { useState, useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { SoftShadows } from "@react-three/drei"
import { Container, Carousel } from 'react-bootstrap'
import * as THREE from 'three';

import Experience from './Experience.jsx'
import WorkExperience from './WorkExperience.jsx'
import CustomNavbar from './components/CustomNavbar'
import CustomText from './components/CustomText'
import Footer from './components/Footer'
import MobilePopup from './components/MobilePopup'

import {textOptions, stageDescription} from './textOptionsData';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'typeface-nunito'
import './style.css'

function App() {
  const [extraInfo, setExtraInfo] = useState(0)
  const [loading, setLoading] = useState(true);

  const handleSelect = () => {
    setExtraInfo(0);
  };

  const loadingManager = useMemo(() => {
    return new THREE.LoadingManager(
      () => {
        console.log("setting false")
        setLoading(false);
      },
      (url) => {
        console.error(`Error loading ${url}`);
      }
    );
  }, []);

  return (
    <>
      <MobilePopup />
      <CustomNavbar />
      <Container className='content-container'>
        <Carousel fade className='h-100' interval={null} onSelect={handleSelect}>
          <Carousel.Item className='h-100'>
            {loading &&
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>}
            <CustomText text={stageDescription[0]}/>
            <div className='extra-info-container' style={{ display: extraInfo == 0 ? 'none' : 'block' }}>
              <h3> {textOptions[extraInfo].title} </h3>
              <p dangerouslySetInnerHTML={{ __html: textOptions[extraInfo].content }} />
            </div>
            <Canvas
              className="experience-canvas"
              shadows={true}
              camera={{
                fov: 45,
                near: 0.1,
                far: 2000,
                position: [-3, 1.5, 4],
              }}
            >
              <SoftShadows
                size={ 20 }
                samples={ 15 }
                focus={ 0 }
              />
              <Experience
                onObjectClick={(e) => setExtraInfo(e)}
                loadingManager={loadingManager}
              />
            </Canvas>
          </Carousel.Item>
          <Carousel.Item className='h-100'>
            <CustomText text={stageDescription[1]}/>
            <div className='extra-info-container' style={{ display: extraInfo == 0 ? 'none' : 'block' }}>
              <h3> {textOptions[extraInfo].title} </h3>
              <p dangerouslySetInnerHTML={{ __html: textOptions[extraInfo].content }} />
            </div>
            <Canvas
              className="experience-canvas"
              shadows={true}
              camera={{
                fov: 45,
                near: 0.1,
                far: 2000,
                position: [-3, 1.5, 4],
              }}
            >
              <WorkExperience
                onObjectClick={(e) => setExtraInfo(e)}
              />
            </Canvas>
          </Carousel.Item>
        </Carousel>
      </Container>
      <Footer />
    </>
  );
}

const root = ReactDOM.createRoot(document.querySelector('#root'))
root.render(<App />)
